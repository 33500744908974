<template>
  <div v-if="popup" tabindex="-1" aria-hidden="true"
    class="overflow-y-auto overflow-x-hidden bg-black bg-opacity-25 fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full flex justify-center items-center h-screen">
      <!-- Modal content -->
      <div class="relative bg-white lg:w-1/3 md:w-2/3 rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Instrumento de Cultura Organizacional, de Cameron e
            Quinn
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5 space-y-4">

          O objetivo deste instrumento é avaliar a cultura
          organizacional. Ao responder às questões, tenha em mente
          a empresa ou organização em que atua.<br /><br />
          São 6 questões, e cada uma tem 4 respostas. Cada resposta
          tem duas situações: a atual e a desejada. Situação atual
          refere-se ao estado da empresa hoje. Situação desejada
          refere-se a como você pensa que a empresa deveria ser
          daqui 5 anos.<br /><br />
          Em cada questão, distribua 100 pontos entre as 4 respostas
          da “situação atual”, sem repetir nenhum valor. Em seguida,
          faça o mesmo para a “situação desejada”.<br /><br />
          Para isso, dê mais pontos aos itens que considera mais
          verdadeiros, e menos pontos aos itens que menos
          concorda, de modo que as distâncias entre os números
          reflitam a diferença de intensidade de cada resposta.<br /><br />
          E lembre-se: queremos saber a SUA opinião, e os resultados
          serão analisados pela média das respostas de todos, sempre
          preservando sua privacidade e anonimato.
          <br /><br />
          <span class="font-bold">
          DICA: Se for preencher pelo celular, deixe a tela na posição horizontal para melhor visualização.</span>
        </div>
        <!-- Modal footer -->
        <div
          class="flex items-center justify-center items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button @click="close"
            class="block mb-2 text-lg rounded-lg bg-blue-400 mt-3 hover:bg-blue-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 focus-visible:ring md:text-base">Entendi,
            quero continuar!</button>
        </div>
      </div>
    </div>
  </div>
  <div class="py-8 sm:py-12 lg:py-16">
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
      <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-2xl">👋 Vamos começar!</h2>

      <div class="mx-auto max-w-lg rounded-lg border bg-white">
        <div class="flex flex-col gap-4 p-4 md:p-8">
          <div class="flex justify-center items-center">
            <img src="@/assets/logo.webp" class="w-72" />
          </div>
          <div>
            <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base font-bold">Nome Completo:</label>
            <input type="text" v-model="nome"
              class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
          </div>

          <div>
            <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base font-bold">E-mail:</label>
            <input type="text" v-model="email"
              class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
          </div>

          <div>
            <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base font-bold">Whatsapp:</label>
            <input type="text" v-model="whatsapp" @input="formatPhoneNumber"
              class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
          </div>

          <div>
            <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base font-bold">Empresa:</label>
            <input type="text" v-model="empresa"
              class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
          </div>

          <button @click="iniciar"
            class="block mb-2 text-lg rounded-lg bg-green-400 mt-3 hover:bg-green-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 focus-visible:ring md:text-base">Iniciar
            questionário</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      nome: "",
      whatsapp: "",
      email: "",
      empresa: "",
      popup: true
    }
  },
  methods: {
    iniciar() {
      if (!this.nome || !this.whatsapp || !this.email || !this.empresa) {
        return alert("Preencha todos os campos.")
      }

      if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        return alert("Preencha com um e-mail válido.")
      }

      if (String(this.whatsapp).length < 8) {
        return alert("Preencha com um número de Whatsapp válido.")
      }

      localStorage.setItem("dados", JSON.stringify({ nome: this.nome, whatsapp: this.whatsapp, email: this.email, empresa: this.empresa, respostas: [], fim: false }))

      location.href = "/question/1"
    },
    close() {
      this.popup = false
    },
    formatPhoneNumber(event) {
      // Remove todos os caracteres que não são dígitos
      let formattedNumber = event.target.value.replace(/\D/g, '');

      // Verifique se o número começa com "55" e, se não, adicione

      if (formattedNumber.length > 11) {
        formattedNumber = formattedNumber.slice(0, -1)
      }

      // Insira um espaço após o código do país (55)
      if (formattedNumber.length > 2) {
        formattedNumber = `(${formattedNumber.slice(0, 2)}${formattedNumber.slice(2)}`;
      }

      // Insira um espaço após o DDD (código de área)
      if (formattedNumber.length > 3) {
        formattedNumber = `${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3)}`;
      }

      // Insira um espaço após os primeiros 5 dígitos do número
      if (formattedNumber.length > 10) {
        formattedNumber = `${formattedNumber.slice(0, 10)}-${formattedNumber.slice(10)}`;
      }


      // Atualize o modelo com o número formatado
      this.whatsapp = formattedNumber;
    }
  }

}

</script>
