<template>
  <section class="p-3 h-full sm:p-5" v-if="question">
    <div class="mx-auto md:max-w-screen-xl sm:p-24 px-4 lg:px-48">
      <!-- Start coding here -->
      <div class="bg-white relative shadow-md sm:rounded-lg overflow-hidden">
        <div
          class="flex text-lg flex-col border-b font-bold md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          {{ question.pergunta }}
        </div>
        <div class="flex flex-col md:flex-row overflow-x-auto">
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="font-medium text-gray-900">A)</div>
            <div class="mt-2">{{ question.a }}</div>
          </div>
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="mt-2">Situação atual</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="atual.a" @input="inputChange" /></div>
          </div>
          <div class="w-full md:w-1/3  p-4 border-b">
            <div class="mt-2">Situação desejada</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="desejada.a" @input="inputChange" /></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row overflow-x-auto">
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="font-medium text-gray-900">B)</div>
            <div class="mt-2">{{ question.b }}</div>
          </div>
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="mt-2">Situação atual</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="atual.b" @input="inputChange" /></div>
          </div>
          <div class="w-full md:w-1/3  p-4 border-b">
            <div class="mt-2">Situação desejada</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="desejada.b" @input="inputChange" /></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row overflow-x-auto">
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="font-medium text-gray-900">C)</div>
            <div class="mt-2">{{ question.c }}</div>
          </div>
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="mt-2">Situação atual</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="atual.c" @input="inputChange" /></div>
          </div>
          <div class="w-full md:w-1/3  p-4 border-b">
            <div class="mt-2">Situação desejada</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="desejada.c" @input="inputChange" /></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row overflow-x-auto">
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="font-medium text-gray-900">D)</div>
            <div class="mt-2">{{ question.d }}</div>
          </div>
          <div class="w-full md:w-1/3 p-4 border-b ">
            <div class="mt-2">Situação atual</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="atual.d" @input="inputChange" /></div>
          </div>
          <div class="w-full md:w-1/3  p-4 border-b">
            <div class="mt-2">Situação desejada</div>
            <div><input type="number" class="border p-2 rounded-lg" v-model="desejada.d" @input="inputChange" /></div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row overflow-x-auto">
          <div v-if="erroratual || errordesejada" class="w-full text-red-500 p-2 text-lg">
            <span class="font-bold">⚠️ Resolva antes de continuar!</span>
            <div v-if="erroratual" class="w-full text-red-500 text-lg">
              {{ erroratual }}
            </div>
            <div v-if="errordesejada" class="w-full text-red-500 text-lg">
              {{ errordesejada }}
            </div>
          </div>
          <div class="w-full p-4 border-b flex justify-end">
            <button @click="continuar"
              id="text_button"
              class="bg-blue-400 hover:bg-blue-500 px-5 py-2 rounded-xl text-lg text-white font-bold">{{text_button}}</button>
          </div>
        </div>
      </div>
      <div class="p-4">
        <footer class="mx-auto max-w-screen-2xl">
          <div class="pt-8 text-center text-sm text-black">
            <span class="font-bold">COMO PREENCHER: As 4 respostas de “situação atual” devem somar 100 pontos, sem repetir
nenhum valor. Em seguida, faça o mesmo para a “situação desejada”. Dê mais pontos aos itens
que considera mais verdadeiros, e menos pontos aos itens que menos concorda. Se for
preencher pelo celular, deixe a tela na posição horizontal para melhor visualização.</span><br/>
            Em caso de dúvidas no preenchimento, favor entrar em contato através do e-mail: lais.fermino@mrtempo.com.br e geovane.santos@mrtempo.com.br ou fale com um responsável da sua empresa.</div>
        </footer>
      </div>
    </div>
  </section>
  <section v-else class="text-gray-600 body-font">
    <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
      <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">🤔 Esta questão não existe!</h1>
      </div>
    </div>
  </section>
</template>

<script>

import perguntas from '@/assets/data/perguntas.json'
import axios from 'axios'

export default {
  name: 'QuestionView',
  data() {
    return {
      question: "",
      erroratual: "Situação Atual: Preencha com números.",
      errordesejada: "Situação Desejada: Preencha com números.",
      text_button: "Continuar",
      atual: {
        a: 0,
        b: 0,
        c: 0,
        d: 0
      },
      desejada: {
        a: 0,
        b: 0,
        c: 0,
        d: 0
      }
    }
  },
  mounted() {
    if (perguntas.length >= Number(this.$route.params.id)) {
      this.question = perguntas[Number(this.$route.params.id) - 1]
    }

    //this.question = perguntas[0]
  },
  methods: {
    async continuar() {
      if (this.erroratual || this.errordesejada) {
        alert("Preencha corretamente para continuar.")
      } else {

        const dados = JSON.parse(localStorage.getItem("dados"))

        dados.respostas.push({ question: this.question, atual: this.atual, desejada: this.desejada })

        if (Number(this.$route.params.id) == perguntas.length) {
          dados.fim = true
          localStorage.setItem("dados", JSON.stringify(dados))
          document.querySelector("#text_button").disabled = true
          this.text_button = "Aguarde..."
          await axios.post('https://hook.us1.make.com/xcrsx65oo7whpp41p7wcdi9gytvli7he', dados)
          window.location.href = "/fim"
        } else {
          localStorage.setItem("dados", JSON.stringify(dados))
          window.location.href = `/question/${Number(this.$route.params.id) + 1}`
        }
      }
    },
    verificarNumerosAtual(a, b, c, d) {

      this.erroratual = ""

      if (a + b + c + d !== 100) {
        this.erroratual = "Situação Atual: A soma dos números é diferente de 100.";
      }

      if (a === b || a === c || a === d || b === c || b === d || c === d) {
        this.erroratual = "Situação Atual: Dois ou mais números são iguais."
        if (a + b + c + d !== 100) {
          this.erroratual = "Situação Atual: Dois ou mais números são iguais e a soma é diferente de 100."
        }
      }

      // Verifica se a soma dos números é igual a 100
    },
    verificarNumerosDesejada(a, b, c, d) {

      this.errordesejada = ""

      if (a + b + c + d !== 100) {
        this.errordesejada = "Situação Desejada: A soma dos números é diferente de 100.";
      }

      if (a === b || a === c || a === d || b === c || b === d || c === d) {
        this.errordesejada = "Situação Desejada: Dois ou mais números são iguais."
        if (a + b + c + d !== 100) {
          this.errordesejada = "Situação Desejada: Dois ou mais números são iguais e a soma é diferente de 100."
        }
      }

      // Verifica se a soma dos números é igual a 100
    },
    inputChange() {
      this.verificarNumerosAtual(this.atual.a, this.atual.b, this.atual.c, this.atual.d)
      this.verificarNumerosDesejada(this.desejada.a, this.desejada.b, this.desejada.c, this.desejada.d)
    }
  }
}
</script>
